
// 文件上传组件
import { PlusOutlined, LoadingOutlined,EyeOutlined ,DeleteOutlined} from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { upload } from '@/api/teamManage'
import { defineComponent, reactive, ref , Ref, watchEffect } from "vue";
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  type?: string;
  size: number;
  originFileObj: any;
}


function getBase64(img: Blob, callback: (base64Url: string) => void) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
}
export default defineComponent({
    name:'upload',
    props:{
        // 请求url
        url:{
            type:String,
            default:()=>{
                return ''
            }
        },
        // 上传说明
        title:{
            type:String,
            default:()=>{
                return ''
            }
        },
        // 是否校验文件上传
        showVal:{
            type:Boolean,
            default:()=>{
                return true
            }
        },
        // 编辑时回显图片
        imgUrl:{
            type:String,
            default:()=>{
                return ''
            }
        }
    },
    components: {
        LoadingOutlined,
        PlusOutlined,
        EyeOutlined,
        DeleteOutlined
    },
    setup(props,{emit}){
        
        // 气泡弹框
        const visible = ref<boolean>(false)

        const fileList = ref<Array<any>>([]);
        const isMask = ref<boolean>(false)
        const loading = ref<boolean>(false);
        // const imageUrl = ref<string>('');
        const url:Ref<string> = ref('');
        const uploadTitle:Ref<string> = ref('');
        const formRefs = ref();
        const show:Ref<boolean> = ref(true)
        const rules = {
            imageUrl: [{ required: true, message: ``, trigger: 'blur' },],
        };
        const form = reactive({
            fileList:[],
            imageUrl:'',
        });


        watchEffect(()=>{
            uploadTitle.value = props.title;
            url.value = props.url;
            show.value = props.showVal;
            form.imageUrl = props.imgUrl;
        })
        
        // 自定义上传
        const fileUpload = (data:any):void =>{
            fileList.value = [];
            const formData = new FormData();
            formData.append('file', data.file)
            loading.value = true;
            upload(formData).then((res:any)=>{
                if(res.code == 200){
                    form.imageUrl = res.data.path_file;
                    loading.value = false;
                    message.success(res.msg)
                    fileList.value.push(res.data)
                    emit('success',fileList.value);
                }else{
                    loading.value = false;
                    message.error(res.msg);
                }
            }).catch((err)=>{
                loading.value = false;
                message.error(err);
            })
        }
        // 校验提示信息
        const errMassage = ()=>{
            return formRefs.value.validate().then(():Promise<string> => {
                return Promise.resolve('')
            }).catch((err:any) => {
                if(fileList.value.length <= 0){
                    return Promise.reject(`请先上传${uploadTitle.value}`)
                }
            });
        }
        // 校验上传的文件
        const beforeUpload = (file: FileItem) => {
            
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error('只能上传图片格式');
            }
            const isLt10M = file.size / 1024 / 1024 <= 10;
            if (!isLt10M) {
                message.error('文件大小不能大于10MB');
            }
            fileList.value = [...fileList.value, file];
            return isJpgOrPng && isLt10M;
        };
        // 预览
        const perView = (url:string):void =>{
            window.open(url)
            visible.value = false;
        }
        // 删除
        const del = ():void =>{
            visible.value = false;
            fileList.value = [];
            form.imageUrl = '';
            message.success('删除成功')
            emit('success',fileList.value);
        }
        
        return {
            fileList,
            loading,
            url,
            uploadTitle,
            form,
            rules,
            formRefs,
            show,
            fileUpload,
            beforeUpload,
            errMassage,
            perView,
            del,
            isMask,
            visible
        };
    }
})
