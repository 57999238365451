// 转换年月
export const getTime = (val:any)=>{
    return val.year()+'-'+ (val.month() +1<=9?'0'+(val.month() +1):val.month() +1)
}
// 转换年月日
export const getPickerTime = (val:any,type:number):string | object =>{
    if(type == 1){
        return val.year()+'-'+ (val.month() +1<=9?'0'+(val.month() +1):val.month() +1)+'-'+val.date()
    }else{
        return {
            start:val[0].year()+'-'+ (val[0].month() +1<=9?'0'+(val[0].month() +1):val[0].month() +1)+'-'+val[0].date(),
            end:val[1].year()+'-'+ (val[1].month() +1<=9?'0'+(val[1].month() +1):val[1].month() +1)+'-'+val[1].date(),
        }
    }
}
// get请求转换url
export const setUrl = (url:string,data:any)=>{
    let strUrl = url;
    if(data){
        for (const key in data) {
            strUrl += `&${data[key] == null || data[key] == ''?'':key}=${data[key]?data[key]:''}`
        }
    }
    return strUrl
}

// 深拷贝
function isObject(o:any) {
    return (typeof o === 'object' || typeof o === 'function') && o !== null
}
export const deepClone = (obj:any)=>{
    if (!isObject(obj)) {
        throw new Error('obj 不是一个对象！')
    }

    let isArray = Array.isArray(obj)
    let cloneObj = isArray ? [] : {}
    for (let key in obj) {
        (cloneObj as any)[key] = isObject(obj[key]) ? deepClone(obj[key]) : obj[key]
    }

    return cloneObj
}

// 二进制流 导出excel
export const downLoadExport = (val:string,name:string) =>{
    const blob = new Blob([val],{ type: 'application/vnd.ms-excel,charset=utf-8' });
    const fileName = name+'.xlsx';
    const linkNode = document.createElement('a');
    linkNode.download = fileName; //a标签的download属性规定下载文件的名称
    linkNode.style.display = 'none';
    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
    document.body.appendChild(linkNode);
    linkNode.click();  //鼠标单击
    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
    document.body.removeChild(linkNode);
}

var now = new Date(); // 当前日期
var nowDayOfWeek = now.getDay(); // 今天本周的第几天
var nowDay = now.getDate(); // 当前日
var nowMonth = now.getMonth(); // 当前月
var nowYear = now.getFullYear(); // 当前年
nowYear += (nowYear < 2000) ? 1900 : 0;
  
var DateUtil = {

    // 获得当前日期
    getNowDay() {
        return this.formatDate(new Date());
    },
 
    // 获得本周的开始时间
    getStartDayOfWeek() {
        var day = nowDayOfWeek || 7;
        return this.formatDate(new Date(now.getFullYear(), nowMonth, nowDay + 1 - day));
    },
 
    //  获得本周的结束时间
    getEndDayOfWeek() {
        var day = nowDayOfWeek || 7;
        return this.formatDate(new Date(now.getFullYear(), nowMonth, nowDay + 7 - day));
    },

    // 获得本月的开始时间
    getStartDayOfMonth() {
        var monthStartDate = new Date(nowYear, nowMonth, 1);
        return this.formatDate(monthStartDate);
    },

    // 获得本月的结束时间
    getEndDayOfMonth() {
        var monthEndDate = new Date(nowYear, nowMonth, this.getMonthDays());
        return this.formatDate(monthEndDate);
    },

    //  获得本月天数
    getMonthDays() {
        var monthStartDate = new Date(nowYear, nowMonth, 1);
        var monthEndDate = new Date(nowYear, nowMonth + 1, 1);
        var days = (Number(monthEndDate) - Number(monthStartDate)) / (1000 * 60 * 60 * 24);
        return days;
    },

    //本年开始时间("s"代表开始,"e"代表结束，dates为数字类型，不传或0代表今年，-1代表去年，1代表明年)
     getYear(type:string, dates?:number) {
        var dd = new Date();
        var n = dates || 0;
        var day;
        var year = dd.getFullYear() + Number(n);
        if (type == "s") {
            day = year + "-01-01";
        };
        if (type == "e") {
            day = year + "-12-31";
        };
        if (!type) {
            day = year + "-01-01/" + year + "-12-31";
        };
        return day;
    },
    /**
     * @param 日期格式化
     * @returns {String}
     */
    formatDate(date:any) {
        var myyear = date.getFullYear();
        var mymonth = date.getMonth() + 1;
        var myweekday = date.getDate();
  
        if (mymonth < 10) {
            mymonth = "0" + mymonth;
        }
        if (myweekday < 10) {
            myweekday = "0" + myweekday;
        }
        return (myyear + "-" + mymonth + "-" + myweekday);
    }
};
 
export default{
    DateUtil
}