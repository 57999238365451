import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";

// 查询
export const queryData =  (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'get',
        url:`${api}/agent-api/api/team_list?status=${data.status}&keywords=${data.keywords}`,
        // data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 编辑团队&创建团队
export const editTeam =  (data:any,url:string): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}${url}`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 编辑获取市的list
export const getCityList = (): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/team_user_city`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 文件上传
export const upload = (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        headers:{
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/json; charset=utf-8; multipart/form-data",
        },
        method:'post',
        url:`${api}/agent-api/api/upload_file`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

export const getDetailsList = (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/detail_team`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 获取代理商级别
export const getUserRole = (data?:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'get',
        url:`${api}/agent-api/api/team_user_role`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 实体卡&体验卡分配卡时长
export const getCardType = (data?:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'get',
        url:`${api}/agent-api/api/card/type`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}